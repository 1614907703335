@use '../../../colors';

.GenderImage {
  position: relative;
  height: 500px;
  width: 100%;
  @media (min-width: 768px) {
    height: calc(100vh - 48px);
  }
}

.GenderImage__title {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  font-size: 24px;
  text-shadow: 0.02em 0.02em 0 hsl(200 50% 30%);
  color: colors.$color-background;
  letter-spacing: 3px;

  @media (min-width: 500px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 768px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) {
    font-size: 28px;
  }
  @media (min-width: 1280px) {
    font-size: 36px;
  }
}

.GenderImage__img {
  object-fit: cover;
  object-position: 50% 25%;
}
.GenderImage__actions {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 700px;
  display: flex;
  justify-content: space-around;
}

.GenderImage__button {
  text-align: center;
  font-size: 28px;
  height: auto;
  line-height: 1.5;
  text-shadow: 0.01em 0.02em 0 hsl(200 50% 30%);
  color: colors.$color-background;
  &:visited,
  &:hover,
  &:focus {
    color: colors.$color-background;
  }
  padding: 5px;
  border-bottom: 3px solid colors.$color-background;
  @media (min-width: 500px) {
    font-size: 28px;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
  }
  @media (min-width: 1280px) {
    font-size: 42px;
  }
}

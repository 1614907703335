@use '../../../mixins';
.SiteInfo {
  .SiteInfo__header {
    font-size: 20px;
  }
  display: flex;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  @media (min-width: 768px) {
    margin-top: 80px;
  }
  .SiteInfo__info {
    font-size: 14px;
    display: inline-block;
    @media (min-width: 768px) {
      font-size: 16px;
    }
  }
  .SiteInfo__img-wrapper {
    position: relative;
    width: 100%;
  }
  .SiteInfo__img {
    padding-left: 50px;
    @include mixins.object-fit(contain);
    float: right;
  }
}

.SiteInfo__phone {
  white-space: nowrap;
}

.SiteInfo__alert {
  font-size: inherit;
  margin-bottom: 30px;
  margin-top: 10px;
}
.nobr {
  white-space: nowrap;
  font-weight: bold;
}

@use '../../../../colors';

.Promo {
  position: relative;
  height: 500px;
  @media (min-width: 768px) {
    height: calc(100vh - 48px);
  }
}
.Promo__heading {
  position: absolute;
  text-align: center;
  text-shadow: 0.02em 0.02em 0 hsl(200 50% 30%);
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  .Promo__title {
    color: colors.$color-background;
    font-size: 24px;
    letter-spacing: 3px;
    @media (min-width: 768px) {
      font-size: 30px;
    }
    @media (min-width: 1024px) {
      font-size: 40px;
    }
    @media (min-width: 1280px) {
      font-size: 48px;
    }
  }

  .Promo__subtitle {
    color: colors.$color-background;
    font-size: 14px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
    @media (min-width: 1024px) {
      font-size: 22px;
    }
    @media (min-width: 1280px) {
      font-size: 24px;
    }
  }
}

.Promo__img {
  object-fit: cover;
}

.Promo__button {
  text-shadow: 0.01em 0.01em 0 hsl(200 50% 30%);
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 24px;
  height: auto;
  line-height: 1.5;
  color: colors.$color-background;
  &:visited,
  &:hover,
  &:focus {
    color: colors.$color-background;
  }
  padding: 5px;
  border-radius: 4px;
  border: 3px solid colors.$color-background;
  @media (min-width: 768px) {
    font-size: 28px;
  }
  @media (min-width: 1024px) {
    font-size: 36px;
  }
  @media (min-width: 1280px) {
    font-size: 44px;
  }
}

@use '../../../colors';

.Section {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  margin-top: 50px;
  .Section__header {
    color: colors.$color-text-accent;
    padding: 0;
    margin: 20px 0 10px 0;
    text-align: center;
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 28px;
      margin: 40px 0 20px 0;
    }
    @media (min-width: 1280px) {
      font-size: 36px;
    }
  }
  &:first-of-type {
    margin-top: 0;
  }
  .Section__subcategories {
    margin-bottom: 20px;
  }
}

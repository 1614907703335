@use '../../../colors';

.Certificate {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  .Certificate__buttons {
    margin: 0 30px;
    padding: 30px;
    gap: 10px;
    position: absolute;
    top: 50%;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.75);
    transform: translateY(-50%);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @media (min-width: 768px) {
      margin: 0 60px;
      padding: 60px;
    }
    @media (min-width: 1024px) {
      margin: 0;
      padding: 40px 80px;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 1000px;
    }
    .Certificate__buttonsHeading {
      flex-basis: 100%;
      text-align: center;
      margin: 10px;
      margin-bottom: 20px;
      @media (min-width: 500px) {
        font-size: 20px;
      }
      @media (min-width: 768px) {
        font-size: 24px;
      }
      @media (min-width: 768px) {
        font-size: 28px;
        margin-bottom: 60px;
      }
      @media (min-width: 1024px) {
        font-size: 36px;
      }
    }
    .Certificate__button {
      text-align: center;
      width: 100%;
      font-size: 18px;
      height: auto;
      line-height: 1.5;
      background: transparent;
      color: colors.$color-text-accent;
      border-radius: 4px;
      border: 1px solid colors.$color-text-accent;
      padding: 20px 20px;
      @media (min-width: 500px) {
        font-size: 20px;
      }
      @media (min-width: 768px) {
        font-size: 24px;
      }
      @media (min-width: 768px) {
        font-size: 28px;
      }
      @media (min-width: 1024px) {
        padding: 20px 20px;
        font-size: 36px;
      }
      @media (min-width: 1280px) {
        font-size: 44px;
      }
    }
  }
  .Certificate__text {
    margin: auto;
    margin-bottom: 30px;
    text-align: center;
    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 20px;
      margin: 0 0 50px 0;
    }
  }
  .Certificate__heading {
    color: colors.$color-text-accent;
    padding: 0;
    margin: 0 0 10px 0;
    text-align: center;
    font-size: 24px;
    @media (min-width: 768px) {
      font-size: 28px;
      margin: 0 0 20px 0;
    }
    @media (min-width: 1280px) {
      font-size: 36px;
    }
  }
  .Certificate__img-wrapper {
    position: relative;
    margin-left: -16px;
    margin-right: -16px;
    @media (min-width: 768px) {
      margin-left: -42px;
      margin-right: -42px;
    }
    @media (min-width: 1280px) {
      margin-left: -84px;
      margin-right: -84px;
    }
  }
  .Certificate__img {
    width: 100%;
    height: 500px;
    object-fit: cover;
    @media (min-width: 768px) {
      height: calc(100vh - 48px);
    }
  }
}

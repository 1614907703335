.HowToOrder {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  margin-top: 60px;
}
.HowToOrder__steps {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  height: auto;
  gap: 30px;
  max-width: 1200px;
}
.HowToOrder__step {
  display: flex;
  width: auto;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;

  @media (min-width: 500px) {
    width: calc(50% - 25px);
  }
  @media (min-width: 1000px) {
    width: calc(33% - 30px);
  }
}

.HowToOrder__image {
  width: auto;
  height: auto;
  object-fit: contain;
  max-width: 100%;
}

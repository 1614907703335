@use '../../colors';

.IndexPage {
  .IndexPage__image-wrapper {
    margin-left: -16px;
    margin-right: -16px;
    @media (min-width: 768px) {
      margin-left: -42px;
      margin-right: -42px;
    }
    @media (min-width: 1280px) {
      margin-left: -84px;
      margin-right: -84px;
    }
  }
  .IndexPage__image-wrapper_gender {
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }
}

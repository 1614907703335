@use '../../../../colors';
.Subcategories {
  white-space: nowrap;
  overflow: auto;
  margin-left: -16px;
  margin-right: -16px;
  @media (min-width: 768px) {
    margin-left: 0;
    margin-right: 0;
  }
  &:not(.Subcategories_row-only) {
    @media (min-width: 1024px) {
      white-space: normal;
      overflow: unset;
      display: flex;
      flex-wrap: wrap;
    }
  }
}
.Subcategories_row-only {
}
.Subcategories_active {
  border: 2px solid black;
}
.Subcategory {
  margin: 10px;
  display: inline-flex;
  flex-direction: column;
  width: 130px;
  text-align: center;
  &:hover {
    .Subcategory__image {
      box-shadow: 0 0 3px rgba(174, 110, 78, 0.5);
    }
  }
}
.Subcategory__image {
  border-radius: 30px;
  max-height: 74px;
  background-color: colors.$color-background;
  height: auto;
  width: 100%;
}

.Subcategories__section {
  display: inline-block;
}

.Subcategory__link {
  font-size: 16px;
  font-weight: normal;
  color: colors.$color-text-main;
}
